.Button {
  background-color: rgba(28, 81, 255, 1);
  border-radius: 2px;
  font-size: 1.125rem;
  border: none;
  padding: 20px 50px;
  color: white;
  cursor: pointer;
  transition: all 0.125s;

  &:hover {
    filter: brightness(115%);
  }
}

.Disabled {
  cursor: not-allowed;
  background-color: grey;

  &:hover {
    background-color: grey;
  }
}
