@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #161b24;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  margin-bottom: 0;
  font-size: 1.5rem;
}

p {
  font-size: 1.125rem;
  margin: 0;
}

.Timer {
  position: absolute;
  top: 0;
  left: 0;
}

.QuestionInput {
  width: 35px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  font-size: 1.125rem;
  text-align: right;
  padding-right: 5px;
}

.QuestionBtnContainer {
  padding-top: 20px;
}

.QuestionBtn {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 10px 20px;
  margin: 0px 20px;
  cursor: pointer;
  box-sizing: border-box;
}

.QuestionBtn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.-highlight {
  border: 1px solid transparent;
  background-color: rgb(0, 153, 255);
}

.-highlight:hover {
  background-color: rgb(0, 153, 255);
}

.Line {
  margin-bottom: 60px;
  margin-top: 30px;
  height: 1px;
  width: 100%;
  background-color: grey;
}

.Marks {
  position: absolute;
  color: grey;
  right: 0;
  bottom: 0;
  padding-right: 10px;
}
