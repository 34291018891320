.Layout {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  color: white;
  background-color: #161b24;
}

.Content {
  max-width: 1200px;
}
