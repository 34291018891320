.Choices {
  cursor: pointer;
  padding: 20px;
  text-align: left;
  font-weight: 300;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }
}

.NoHover:hover {
  background-color: initial;
  cursor: initial;
}

.Correct {
  border: 3px rgb(97, 255, 150) solid;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 17px;
}

.Incorrect {
  border: 3px rgb(255, 97, 97) solid;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 17px;
}

.NextBtn {
  margin-top: 40px;
  margin-bottom: 20px;
}

.Question {
  display: flex;
  flex-direction: row;
  text-align: left;
  font-weight: 400;
  justify-content: space-between;
}

.ChosenAnswer {
  color: grey;
  padding: 1rem 0;
}
